var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("default-layout", [
    _c(
      "div",
      {
        staticClass: "spainhn-solid-red-background d-flex",
        staticStyle: { "min-height": "5vh", width: "100%" },
      },
      [
        _c("div", { staticClass: "d-flex ps-5 my-auto container" }, [
          _c(
            "p",
            {
              staticClass: "my-auto pb-0 mb-0 spainhn-small-text",
              attrs: { role: "button" },
              on: { click: _vm.goToCastillosYPalacios },
            },
            [_vm._v("Spain Heritage Network >> ")]
          ),
          _c(
            "p",
            { staticClass: "my-auto pb-0 mb-0 ms-2 spainhn-small-text" },
            [_vm._v("POLÍTICA DE SEGURIDAD DE LA INFORMACIÓN")]
          ),
        ]),
      ]
    ),
    _c("div", { staticClass: "container-fluid legal-main px-lx-5 px-1" }, [
      _c("div", { staticClass: "container-fluid mb-5" }, [
        _c("div", { staticClass: "row text-start margen-estandard px-lg-5" }, [
          _c("div", { staticClass: "d-flex flex-column pt-3 col-12 px-xl-5" }, [
            _c(
              "p",
              {
                staticStyle: {
                  "margin-top": "0cm",
                  "margin-right": "0cm",
                  "margin-bottom": "8.0pt",
                  "margin-left": "0cm",
                  "text-align": "justify",
                  "line-height": "110%",
                  "font-size": "15px",
                  "font-family": '"Arial Nova Light",sans-serif',
                },
              },
              [
                _c("strong", [
                  _c(
                    "span",
                    {
                      staticStyle: {
                        "font-size": "24px",
                        "line-height": "110%",
                      },
                    },
                    [_vm._v("Política de Seguridad de la Información ENS")]
                  ),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticStyle: {
                  "margin-top": "0cm",
                  "margin-right": "0cm",
                  "margin-bottom": "8.0pt",
                  "margin-left": "0cm",
                  "text-align": "justify",
                  "line-height": "110%",
                  "font-size": "15px",
                  "font-family": '"Arial Nova Light",sans-serif',
                  border: "none",
                  "border-bottom": "solid #C00000 1.0pt",
                  padding: "0cm 0cm 1.0pt 0cm",
                },
              },
              [
                _c(
                  "h1",
                  {
                    staticStyle: {
                      "margin-top": "18.0pt",
                      "margin-right": "0cm",
                      "margin-bottom": "8.0pt",
                      "margin-left": "21.55pt",
                      "text-align": "justify",
                      "text-indent": "-21.55pt",
                      border: "none",
                      padding: "0cm",
                      "font-size": "24px",
                      "font-family": '"Arial Nova Light",sans-serif',
                      color: "#C00000",
                    },
                  },
                  [
                    _c(
                      "span",
                      { staticStyle: { "font-family": '"Arial Nova Light"' } },
                      [_vm._v("1     ")]
                    ),
                    _vm._v("INTRODUCCIÓN"),
                  ]
                ),
              ]
            ),
            _c(
              "p",
              {
                staticStyle: {
                  "margin-top": "0cm",
                  "margin-right": "0cm",
                  "margin-bottom": "8.0pt",
                  "margin-left": "0cm",
                  "text-align": "justify",
                  "line-height": "110%",
                  "font-size": "15px",
                  "font-family": '"Arial Nova Light",sans-serif',
                },
              },
              [
                _vm._v(
                  " Spain Heritage Tours SL depende de los sistemas TIC (Tecnologías de Información y Comunicaciones) para alcanzar sus objetivos. Estos sistemas deben ser administrados con diligencia, tomando las medidas adecuadas para protegerlos frente a daños accidentales o deliberados que puedan afectar a la disponibilidad, integridad o confidencialidad de la información tratada o los servicios prestados."
                ),
              ]
            ),
            _c(
              "p",
              {
                staticStyle: {
                  "margin-top": "0cm",
                  "margin-right": "0cm",
                  "margin-bottom": "8.0pt",
                  "margin-left": "0cm",
                  "text-align": "justify",
                  "line-height": "110%",
                  "font-size": "15px",
                  "font-family": '"Arial Nova Light",sans-serif',
                },
              },
              [
                _vm._v(
                  " El objetivo de la seguridad de la información es garantizar la calidad de la información y la prestación continuada de los servicios, actuando preventivamente, supervisando la actividad diaria y reaccionando con presteza a los incidentes."
                ),
              ]
            ),
            _c(
              "p",
              {
                staticStyle: {
                  "margin-top": "0cm",
                  "margin-right": "0cm",
                  "margin-bottom": "8.0pt",
                  "margin-left": "0cm",
                  "text-align": "justify",
                  "line-height": "110%",
                  "font-size": "15px",
                  "font-family": '"Arial Nova Light",sans-serif',
                },
              },
              [
                _vm._v(
                  " Los sistemas TIC deben estar protegidos contra amenazas de rápida evolución con potencial para incidir en la confidencialidad, integridad, disponibilidad, uso previsto y valor de la información y los servicios. Para defenderse de estas amenazas, se requiere una estrategia que se adapte a los cambios en las condiciones del entorno para garantizar la prestación continua de los servicios. Esto implica que los departamentos deben aplicar las medidas mínimas de seguridad exigidas por el Esquema Nacional de Seguridad, así como realizar un seguimiento continuo de los niveles de prestación de servicios, seguir y analizar las vulnerabilidades reportadas, y preparar una respuesta efectiva a los incidentes para garantizar la continuidad de los servicios prestados."
                ),
              ]
            ),
            _c(
              "p",
              {
                staticStyle: {
                  "margin-top": "0cm",
                  "margin-right": "0cm",
                  "margin-bottom": "8.0pt",
                  "margin-left": "0cm",
                  "text-align": "justify",
                  "line-height": "110%",
                  "font-size": "15px",
                  "font-family": '"Arial Nova Light",sans-serif',
                },
              },
              [
                _vm._v(
                  " Los diferentes departamentos deben cerciorarse de que la seguridad TIC es una parte integral de cada etapa del ciclo de vida del sistema, desde su concepción hasta su retirada de servicio, pasando por las decisiones de desarrollo o adquisición y las actividades de explotación. Los requisitos de seguridad y las necesidades de financiación, deben ser identificados e incluidos en la planificación, en la solicitud de ofertas, y en pliegos de licitación para proyectos de TIC."
                ),
              ]
            ),
            _c(
              "p",
              {
                staticStyle: {
                  "margin-top": "0cm",
                  "margin-right": "0cm",
                  "margin-bottom": "8.0pt",
                  "margin-left": "0cm",
                  "text-align": "justify",
                  "line-height": "110%",
                  "font-size": "15px",
                  "font-family": '"Arial Nova Light",sans-serif',
                },
              },
              [
                _vm._v(
                  " Los departamentos deben estar preparados para prevenir, detectar, reaccionar y recuperarse de incidentes, de acuerdo al Artículo 7 del ENS (Artículo 8. Prevención, detección, respuesta y conservación. Real Decreto 311/2022, de 3 de mayo, por el que se regula el Esquema Nacional de Seguridad.)"
                ),
              ]
            ),
            _c(
              "div",
              {
                staticStyle: {
                  "margin-top": "0cm",
                  "margin-right": "0cm",
                  "margin-bottom": "8.0pt",
                  "margin-left": "0cm",
                  "text-align": "justify",
                  "line-height": "110%",
                  "font-size": "15px",
                  "font-family": '"Arial Nova Light",sans-serif',
                },
              },
              [
                _c(
                  "ol",
                  {
                    staticStyle: {
                      "margin-bottom": "0cm",
                      "list-style-type": "decimal",
                    },
                  },
                  [
                    _c(
                      "li",
                      {
                        staticStyle: {
                          "margin-top": "0cm",
                          "margin-right": "0cm",
                          "margin-bottom": "8.0pt",
                          "margin-left": "0cm",
                          "text-align": "justify",
                          "line-height": "110%",
                          "font-size": "15px",
                          "font-family": '"Arial Nova Light",sans-serif',
                        },
                      },
                      [
                        _c(
                          "h2",
                          {
                            staticStyle: {
                              "margin-top": "18.0pt",
                              "margin-right": "0cm",
                              "margin-bottom": "6.0pt",
                              "margin-left": "28.9pt",
                              "text-align": "justify",
                              "text-indent": "-28.9pt",
                              "line-height": "110%",
                              "font-size": "19px",
                              "font-family": '"Arial Nova Light",sans-serif',
                              color: "#C00000",
                            },
                          },
                          [_vm._v(" PREVENCIÓN")]
                        ),
                      ]
                    ),
                  ]
                ),
              ]
            ),
            _c(
              "p",
              {
                staticStyle: {
                  "margin-top": "0cm",
                  "margin-right": "0cm",
                  "margin-bottom": "8.0pt",
                  "margin-left": "0cm",
                  "text-align": "justify",
                  "line-height": "110%",
                  "font-size": "15px",
                  "font-family": '"Arial Nova Light",sans-serif',
                },
              },
              [
                _vm._v(
                  " Los departamentos deben evitar, o al menos prevenir en la medida de lo posible, que la información o los servicios se vean perjudicados por incidentes de seguridad. Para ello los departamentos deben implementar las medidas mínimas de seguridad determinadas por el ENS, así como cualquier control adicional identificado a través de una evaluación de amenazas y riesgos. Estos controles, y los roles y responsabilidades de seguridad de todo el personal, deben estar claramente definidos y documentados. Para garantizar el cumplimiento de la política, los departamentos deben: Autorizar los sistemas antes de entrar en operación."
                ),
              ]
            ),
            _c(
              "ul",
              {
                staticClass: "decimal_type",
                staticStyle: { "list-style-type": "disc" },
              },
              [
                _c("li", [
                  _vm._v(
                    "Evaluar regularmente la seguridad, incluyendo evaluaciones de los cambios de configuración realizados de forma rutinaria."
                  ),
                ]),
                _c("li", [
                  _vm._v(
                    "Solicitar la revisión periódica por parte de terceros con el fin de obtener una evaluación independiente."
                  ),
                  _c(
                    "ol",
                    {
                      staticClass: "decimal_type",
                      staticStyle: { "list-style-type": "decimal" },
                    },
                    [
                      _c("li", [
                        _c(
                          "h2",
                          {
                            staticStyle: {
                              "margin-top": "18.0pt",
                              "margin-right": "0cm",
                              "margin-bottom": "6.0pt",
                              "margin-left": "28.9pt",
                              "text-align": "justify",
                              "text-indent": "-28.9pt",
                              "line-height": "110%",
                              "font-size": "19px",
                              "font-family": '"Arial Nova Light",sans-serif',
                              color: "#C00000",
                            },
                          },
                          [_vm._v(" DETECCIÓN")]
                        ),
                      ]),
                    ]
                  ),
                ]),
              ]
            ),
            _c(
              "p",
              {
                staticStyle: {
                  "margin-top": "0cm",
                  "margin-right": "0cm",
                  "margin-bottom": "8.0pt",
                  "margin-left": "0cm",
                  "text-align": "justify",
                  "line-height": "110%",
                  "font-size": "15px",
                  "font-family": '"Arial Nova Light",sans-serif',
                },
              },
              [
                _vm._v(
                  " Dado que los servicios se puede degradar rápidamente debido a incidentes, que van desde una simple desaceleración hasta su detención, los servicios deben monitorizar la operación de manera continua para detectar anomalías en los niveles de prestación de los servicios y actuar en consecuencia según lo establecido en el Artículo 10 del ENS. (Artículo 9. Vigilancia continua y Reevaluación periódica)."
                ),
              ]
            ),
            _c(
              "p",
              {
                staticStyle: {
                  "margin-top": "0cm",
                  "margin-right": "0cm",
                  "margin-bottom": "8.0pt",
                  "margin-left": "0cm",
                  "text-align": "justify",
                  "line-height": "110%",
                  "font-size": "15px",
                  "font-family": '"Arial Nova Light",sans-serif',
                },
              },
              [
                _vm._v(
                  " La monitorización es especialmente relevante cuando se establecen líneas de defensa de acuerdo con el Artículo 9 del ENS. (Artículo 8. Existencia de Líneas de defensa)."
                ),
              ]
            ),
            _c(
              "p",
              {
                staticStyle: {
                  "margin-top": "0cm",
                  "margin-right": "0cm",
                  "margin-bottom": "8.0pt",
                  "margin-left": "0cm",
                  "text-align": "justify",
                  "line-height": "110%",
                  "font-size": "15px",
                  "font-family": '"Arial Nova Light",sans-serif',
                },
              },
              [
                _vm._v(
                  " Se establecerán mecanismos de detección, análisis y reporte que lleguen a los responsables regularmente y cuando se produce una desviación significativa de los parámetros que se hayan preestablecido como normales."
                ),
              ]
            ),
            _c(
              "div",
              {
                staticStyle: {
                  "margin-top": "0cm",
                  "margin-right": "0cm",
                  "margin-bottom": "8.0pt",
                  "margin-left": "0cm",
                  "text-align": "justify",
                  "line-height": "110%",
                  "font-size": "15px",
                  "font-family": '"Arial Nova Light",sans-serif',
                },
              },
              [
                _c(
                  "ol",
                  {
                    staticStyle: {
                      "margin-bottom": "0cm",
                      "list-style-type": "decimal",
                    },
                  },
                  [
                    _c(
                      "li",
                      {
                        staticStyle: {
                          "margin-top": "0cm",
                          "margin-right": "0cm",
                          "margin-bottom": "8.0pt",
                          "margin-left": "0cm",
                          "text-align": "justify",
                          "line-height": "110%",
                          "font-size": "15px",
                          "font-family": '"Arial Nova Light",sans-serif',
                        },
                      },
                      [
                        _c(
                          "h2",
                          {
                            staticStyle: {
                              "margin-top": "18.0pt",
                              "margin-right": "0cm",
                              "margin-bottom": "6.0pt",
                              "margin-left": "28.9pt",
                              "text-align": "justify",
                              "text-indent": "-28.9pt",
                              "line-height": "110%",
                              "font-size": "19px",
                              "font-family": '"Arial Nova Light",sans-serif',
                              color: "#C00000",
                            },
                          },
                          [_vm._v(" RESPUESTA")]
                        ),
                      ]
                    ),
                  ]
                ),
              ]
            ),
            _c(
              "p",
              {
                staticStyle: {
                  "margin-top": "0cm",
                  "margin-right": "0cm",
                  "margin-bottom": "8.0pt",
                  "margin-left": "0cm",
                  "text-align": "justify",
                  "line-height": "110%",
                  "font-size": "15px",
                  "font-family": '"Arial Nova Light",sans-serif',
                },
              },
              [
                _vm._v(
                  " La Entidad ha establecido mecanismos para responder eficazmente a los incidentes de seguridad."
                ),
              ]
            ),
            _c(
              "p",
              {
                staticStyle: {
                  "margin-top": "0cm",
                  "margin-right": "0cm",
                  "margin-bottom": "8.0pt",
                  "margin-left": "0cm",
                  "text-align": "justify",
                  "line-height": "110%",
                  "font-size": "15px",
                  "font-family": '"Arial Nova Light",sans-serif',
                },
              },
              [
                _vm._v(
                  " Se ha designado un punto de contacto para las comunicaciones con respecto a incidentes detectados en otros departamentos o en otros organismos."
                ),
              ]
            ),
            _c(
              "p",
              {
                staticStyle: {
                  "margin-top": "0cm",
                  "margin-right": "0cm",
                  "margin-bottom": "8.0pt",
                  "margin-left": "0cm",
                  "text-align": "justify",
                  "line-height": "110%",
                  "font-size": "15px",
                  "font-family": '"Arial Nova Light",sans-serif',
                },
              },
              [
                _vm._v(
                  " Se han establecido protocolos para el intercambio de información relacionada con el incidente. Esto incluye comunicaciones, en ambos sentidos, con los Equipos de Respuesta a Emergencias (CERT)."
                ),
              ]
            ),
            _c(
              "div",
              {
                staticStyle: {
                  "margin-top": "0cm",
                  "margin-right": "0cm",
                  "margin-bottom": "8.0pt",
                  "margin-left": "0cm",
                  "text-align": "justify",
                  "line-height": "110%",
                  "font-size": "15px",
                  "font-family": '"Arial Nova Light",sans-serif',
                },
              },
              [
                _c(
                  "ol",
                  {
                    staticStyle: {
                      "margin-bottom": "0cm",
                      "list-style-type": "decimal",
                    },
                  },
                  [
                    _c(
                      "li",
                      {
                        staticStyle: {
                          "margin-top": "0cm",
                          "margin-right": "0cm",
                          "margin-bottom": "8.0pt",
                          "margin-left": "0cm",
                          "text-align": "justify",
                          "line-height": "110%",
                          "font-size": "15px",
                          "font-family": '"Arial Nova Light",sans-serif',
                        },
                      },
                      [
                        _c(
                          "h2",
                          {
                            staticStyle: {
                              "margin-top": "18.0pt",
                              "margin-right": "0cm",
                              "margin-bottom": "6.0pt",
                              "margin-left": "28.9pt",
                              "text-align": "justify",
                              "text-indent": "-28.9pt",
                              "line-height": "110%",
                              "font-size": "19px",
                              "font-family": '"Arial Nova Light",sans-serif',
                              color: "#C00000",
                            },
                          },
                          [_vm._v(" RECUPERACIÓN")]
                        ),
                      ]
                    ),
                  ]
                ),
              ]
            ),
            _c(
              "p",
              {
                staticStyle: {
                  "margin-top": "0cm",
                  "margin-right": "0cm",
                  "margin-bottom": "8.0pt",
                  "margin-left": "0cm",
                  "text-align": "justify",
                  "line-height": "110%",
                  "font-size": "15px",
                  "font-family": '"Arial Nova Light",sans-serif',
                },
              },
              [
                _vm._v(
                  " Para garantizar la disponibilidad de los servicios críticos, la entidad ha desarrollado planes de continuidad de los sistemas TIC como parte de su plan general de continuidad de negocio y actividades de recuperación."
                ),
              ]
            ),
            _c(
              "div",
              {
                staticStyle: {
                  "margin-top": "0cm",
                  "margin-right": "0cm",
                  "margin-bottom": "8.0pt",
                  "margin-left": "0cm",
                  "text-align": "justify",
                  "line-height": "110%",
                  "font-size": "15px",
                  "font-family": '"Arial Nova Light",sans-serif',
                  border: "none",
                  "border-bottom": "solid #C00000 1.0pt",
                  padding: "0cm 0cm 1.0pt 0cm",
                },
              },
              [
                _c(
                  "h1",
                  {
                    staticStyle: {
                      "margin-top": "18.0pt",
                      "margin-right": "0cm",
                      "margin-bottom": "8.0pt",
                      "margin-left": "21.55pt",
                      "text-align": "justify",
                      "text-indent": "-21.55pt",
                      border: "none",
                      padding: "0cm",
                      "font-size": "24px",
                      "font-family": '"Arial Nova Light",sans-serif',
                      color: "#C00000",
                    },
                  },
                  [
                    _c(
                      "span",
                      { staticStyle: { "font-family": '"Arial Nova Light"' } },
                      [_vm._v("2     ")]
                    ),
                    _vm._v("ALCANCE"),
                  ]
                ),
              ]
            ),
            _c(
              "p",
              {
                staticStyle: {
                  "margin-top": "0cm",
                  "margin-right": "0cm",
                  "margin-bottom": "8.0pt",
                  "margin-left": "0cm",
                  "text-align": "justify",
                  "line-height": "110%",
                  "font-size": "15px",
                  "font-family": '"Arial Nova Light",sans-serif',
                },
              },
              [
                _vm._v(
                  " Esta política se aplica a todos los sistemas TIC de la entidad y a todos los miembros de la organización, implicados en Servicios y Proyectos destinados al sector público, que requieran la aplicación de ENS, sin excepciones."
                ),
              ]
            ),
            _c(
              "div",
              {
                staticStyle: {
                  "margin-top": "0cm",
                  "margin-right": "0cm",
                  "margin-bottom": "8.0pt",
                  "margin-left": "0cm",
                  "text-align": "justify",
                  "line-height": "110%",
                  "font-size": "15px",
                  "font-family": '"Arial Nova Light",sans-serif',
                  border: "none",
                  "border-bottom": "solid #C00000 1.0pt",
                  padding: "0cm 0cm 1.0pt 0cm",
                },
              },
              [
                _c(
                  "h1",
                  {
                    staticStyle: {
                      "margin-top": "18.0pt",
                      "margin-right": "0cm",
                      "margin-bottom": "8.0pt",
                      "margin-left": "21.55pt",
                      "text-align": "justify",
                      "text-indent": "-21.55pt",
                      border: "none",
                      padding: "0cm",
                      "font-size": "24px",
                      "font-family": '"Arial Nova Light",sans-serif',
                      color: "#C00000",
                    },
                  },
                  [
                    _c(
                      "span",
                      { staticStyle: { "font-family": '"Arial Nova Light"' } },
                      [_vm._v("3     ")]
                    ),
                    _vm._v("MISIÓN "),
                  ]
                ),
              ]
            ),
            _c(
              "p",
              {
                staticStyle: {
                  "margin-top": "0cm",
                  "margin-right": "0cm",
                  "margin-bottom": "8.0pt",
                  "margin-left": "0cm",
                  "text-align": "justify",
                  "line-height": "110%",
                  "font-size": "15px",
                  "font-family": '"Arial Nova Light",sans-serif',
                },
              },
              [_vm._v(" Los principales objetivos que se persiguen son:")]
            ),
            _c("ul", { staticStyle: { "list-style-type": "disc" } }, [
              _c("li", [
                _vm._v(
                  "Fomentar la relación electrónica del usuario con la Entidad o la de sus Clientes."
                ),
              ]),
              _c("li", [
                _vm._v("Reducir tiempos de espera de atención al usuario."),
              ]),
              _c("li", [
                _vm._v(
                  "Acortar tiempos de espera en la resolución de trámites solicitados por el usuario."
                ),
              ]),
              _c("li", [
                _vm._v(
                  "Desarrollar un sistema de gestión de información documental que facilite un rápido acceso del personal del servicio a la información solicitada por el usuario."
                ),
              ]),
            ]),
            _c(
              "div",
              {
                staticStyle: {
                  "margin-top": "0cm",
                  "margin-right": "0cm",
                  "margin-bottom": "8.0pt",
                  "margin-left": "0cm",
                  "text-align": "justify",
                  "line-height": "110%",
                  "font-size": "15px",
                  "font-family": '"Arial Nova Light",sans-serif',
                  border: "none",
                  "border-bottom": "solid #C00000 1.0pt",
                  padding: "0cm 0cm 1.0pt 0cm",
                },
              },
              [
                _c(
                  "h1",
                  {
                    staticStyle: {
                      "margin-top": "18.0pt",
                      "margin-right": "0cm",
                      "margin-bottom": "8.0pt",
                      "margin-left": "21.55pt",
                      "text-align": "justify",
                      "text-indent": "-21.55pt",
                      border: "none",
                      padding: "0cm",
                      "font-size": "24px",
                      "font-family": '"Arial Nova Light",sans-serif',
                      color: "#C00000",
                    },
                  },
                  [
                    _c(
                      "span",
                      { staticStyle: { "font-family": '"Arial Nova Light"' } },
                      [_vm._v("4     ")]
                    ),
                    _vm._v("MARCO NORMATIVO"),
                  ]
                ),
              ]
            ),
            _c(
              "p",
              {
                staticStyle: {
                  "margin-top": "0cm",
                  "margin-right": "0cm",
                  "margin-bottom": "8.0pt",
                  "margin-left": "0cm",
                  "text-align": "justify",
                  "line-height": "110%",
                  "font-size": "15px",
                  "font-family": '"Arial Nova Light",sans-serif',
                },
              },
              [_vm._v(" Esta política se enmarca en la siguiente legislación:")]
            ),
            _c("ol", { staticStyle: { "list-style-type": "decimal" } }, [
              _c("li", [
                _vm._v(
                  "RD 311/2022 Real Decreto 311/2022, de 3 de mayo, por el que se regula el Esquema Nacional de Seguridad. BOE de 04 de mayo de 2022."
                ),
              ]),
              _c("li", [
                _vm._v(
                  "Ley 30/1992, de 26 de noviembre, de Régimen Jurídico de las Administraciones Públicas y del Procedimiento Administrativo Común."
                ),
              ]),
              _c("li", [
                _vm._v(
                  "Ley 40/2015, de 1 de octubre, de Régimen Jurídico del Sector Público."
                ),
              ]),
              _c("li", [
                _vm._v(
                  "Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo, de 27 de abril de 2016. relativo a la protección de las personas físicas en lo que respecta al tratamiento de datos personales y a la libre circulación de estos datos."
                ),
              ]),
              _c("li", [
                _vm._v(
                  "Ley Orgánica 3/2018, de 5 de diciembre, de Protección de Datos Personales y garantía de los derechos digitales."
                ),
              ]),
              _c("li", [
                _vm._v(
                  "Ley 11/2007, de 22 de junio, de acceso electrónico de los ciudadanos a los Servicios Públicos."
                ),
              ]),
            ]),
            _c(
              "div",
              {
                staticStyle: {
                  "margin-top": "0cm",
                  "margin-right": "0cm",
                  "margin-bottom": "8.0pt",
                  "margin-left": "0cm",
                  "text-align": "justify",
                  "line-height": "110%",
                  "font-size": "15px",
                  "font-family": '"Arial Nova Light",sans-serif',
                  border: "none",
                  "border-bottom": "solid #C00000 1.0pt",
                  padding: "0cm 0cm 1.0pt 0cm",
                },
              },
              [
                _c(
                  "h1",
                  {
                    staticStyle: {
                      "margin-top": "18.0pt",
                      "margin-right": "0cm",
                      "margin-bottom": "8.0pt",
                      "margin-left": "21.55pt",
                      "text-align": "justify",
                      "text-indent": "-21.55pt",
                      border: "none",
                      padding: "0cm",
                      "font-size": "24px",
                      "font-family": '"Arial Nova Light",sans-serif',
                      color: "#C00000",
                    },
                  },
                  [
                    _c(
                      "span",
                      { staticStyle: { "font-family": '"Arial Nova Light"' } },
                      [_vm._v("5     ")]
                    ),
                    _vm._v("Organización de la Seguridad"),
                  ]
                ),
              ]
            ),
            _c(
              "div",
              {
                staticStyle: {
                  "margin-top": "0cm",
                  "margin-right": "0cm",
                  "margin-bottom": "8.0pt",
                  "margin-left": "0cm",
                  "text-align": "justify",
                  "line-height": "110%",
                  "font-size": "15px",
                  "font-family": '"Arial Nova Light",sans-serif',
                },
              },
              [
                _c(
                  "ol",
                  {
                    staticStyle: {
                      "margin-bottom": "0cm",
                      "list-style-type": "decimal",
                    },
                    attrs: { start: "5" },
                  },
                  [
                    _c(
                      "li",
                      {
                        staticStyle: {
                          "margin-top": "0cm",
                          "margin-right": "0cm",
                          "margin-bottom": "8.0pt",
                          "margin-left": "0cm",
                          "text-align": "justify",
                          "line-height": "110%",
                          "font-size": "15px",
                          "font-family": '"Arial Nova Light",sans-serif',
                        },
                      },
                      [
                        _c(
                          "h2",
                          {
                            staticStyle: {
                              "margin-top": "18.0pt",
                              "margin-right": "0cm",
                              "margin-bottom": "6.0pt",
                              "margin-left": "28.9pt",
                              "text-align": "justify",
                              "text-indent": "-28.9pt",
                              "line-height": "110%",
                              "font-size": "19px",
                              "font-family": '"Arial Nova Light",sans-serif',
                              color: "#C00000",
                            },
                          },
                          [_vm._v(" Comité: Funciones y Responsabilidades")]
                        ),
                      ]
                    ),
                  ]
                ),
              ]
            ),
            _c(
              "p",
              {
                staticStyle: {
                  "margin-top": "0cm",
                  "margin-right": "0cm",
                  "margin-bottom": "8.0pt",
                  "margin-left": "0cm",
                  "text-align": "justify",
                  "line-height": "110%",
                  "font-size": "15px",
                  "font-family": '"Arial Nova Light",sans-serif',
                },
              },
              [_vm._v(" El Comité de Seguridad TIC estará formado por:")]
            ),
            _c("ul", { staticStyle: { "list-style-type": "disc" } }, [
              _c("li", [
                _vm._v(
                  "El Presidente o Director de Desarrollo de Red: actuará como Alta Dirección, Titular, Responsable del Tratamiento, Responsable de la Información (RINFO) y Responsable del Servicio (RSER)."
                ),
              ]),
              _c("li", [
                _vm._v(
                  "El Director de Tecnología: será el Responsable de Seguridad de la Información (RSEG) y el Responsable del Sistema (RSIS)."
                ),
              ]),
              _c("li", [
                _vm._v(
                  "El Técnico TI: desempeñará el rol de Administrador de Seguridad (AS)."
                ),
              ]),
            ]),
            _c(
              "p",
              {
                staticStyle: {
                  "margin-top": "0cm",
                  "margin-right": "0cm",
                  "margin-bottom": "8.0pt",
                  "margin-left": "0cm",
                  "text-align": "justify",
                  "line-height": "110%",
                  "font-size": "15px",
                  "font-family": '"Arial Nova Light",sans-serif',
                },
              },
              [
                _vm._v(
                  " El CTO actuará como Secretario del Comité de Seguridad TIC, encargándose de convocar las reuniones y levantar acta de las mismas."
                ),
              ]
            ),
            _c(
              "p",
              {
                staticStyle: {
                  "margin-top": "0cm",
                  "margin-right": "0cm",
                  "margin-bottom": "8.0pt",
                  "margin-left": "0cm",
                  "text-align": "justify",
                  "line-height": "110%",
                  "font-size": "15px",
                  "font-family": '"Arial Nova Light",sans-serif',
                },
              },
              [
                _vm._v(
                  " El Comité de Seguridad TIC reportará a la Alta Dirección."
                ),
              ]
            ),
            _c(
              "p",
              {
                staticStyle: {
                  "margin-top": "0cm",
                  "margin-right": "0cm",
                  "margin-bottom": "8.0pt",
                  "margin-left": "0cm",
                  "text-align": "justify",
                  "line-height": "110%",
                  "font-size": "15px",
                  "font-family": '"Arial Nova Light",sans-serif',
                },
              },
              [
                _vm._v(
                  " El Comité de Seguridad TIC tendrá las siguientes funciones:"
                ),
              ]
            ),
            _c(
              "ul",
              {
                staticClass: "decimal_type",
                staticStyle: { "list-style-type": "disc" },
              },
              [
                _c("li", [
                  _vm._v(
                    "Coordinar y aprobar las acciones en materia de seguridad de la información."
                  ),
                ]),
                _c("li", [
                  _vm._v("Impulsar la cultura en seguridad de la información."),
                ]),
                _c("li", [
                  _vm._v(
                    "Participar en la categorización de los sistemas y el análisis de riesgos. "
                  ),
                ]),
                _c("li", [
                  _vm._v(
                    "Revisar la documentación relacionada con la seguridad del sistema."
                  ),
                ]),
                _c("li", [
                  _vm._v(
                    "Resolver discrepancias y problemas que puedan surgir en la gestión de la seguridad."
                  ),
                  _c(
                    "ol",
                    {
                      staticClass: "decimal_type",
                      staticStyle: { "list-style-type": "decimal" },
                      attrs: { start: "5" },
                    },
                    [
                      _c("li", [
                        _c(
                          "h2",
                          {
                            staticStyle: {
                              "margin-top": "18.0pt",
                              "margin-right": "0cm",
                              "margin-bottom": "6.0pt",
                              "margin-left": "28.9pt",
                              "text-align": "justify",
                              "text-indent": "-28.9pt",
                              "line-height": "110%",
                              "font-size": "19px",
                              "font-family": '"Arial Nova Light",sans-serif',
                              color: "#C00000",
                            },
                          },
                          [_vm._v(" Roles: Funciones y Responsabilidades")]
                        ),
                      ]),
                    ]
                  ),
                ]),
              ]
            ),
            _c(
              "p",
              {
                staticStyle: {
                  "margin-top": "0cm",
                  "margin-right": "0cm",
                  "margin-bottom": "8.0pt",
                  "margin-left": "0cm",
                  "text-align": "justify",
                  "line-height": "110%",
                  "font-size": "15px",
                  "font-family": '"Arial Nova Light",sans-serif',
                },
              },
              [
                _vm._v(
                  " Responsabilidades de la Alta Dirección (Presidente o Director de Desarrollo de Red): "
                ),
              ]
            ),
            _c("ul", { staticStyle: { "list-style-type": "disc" } }, [
              _c("li", [
                _vm._v(
                  "Fijar los objetivos estratégicos en materia de seguridad de la información."
                ),
              ]),
              _c("li", [
                _vm._v(
                  "Organizar adecuadamente los recursos y dirigir la actividad relacionada con la seguridad."
                ),
              ]),
              _c("li", [
                _vm._v(
                  "Aprobar la Política de Seguridad de la Información y, en su caso, la Política de Protección de Datos."
                ),
              ]),
              _c("li", [
                _vm._v(
                  "Facilitar los recursos adecuados para alcanzar los objetivos propuestos y velar por su cumplimiento."
                ),
              ]),
              _c("li", [
                _vm._v(
                  "Actuar como Responsable del Tratamiento de Datos Personales."
                ),
              ]),
              _c("li", [
                _vm._v(
                  "Asumir el rol de Responsable de la Información, teniendo la responsabilidad última del uso y protección de la información."
                ),
              ]),
              _c("li", [
                _vm._v(
                  "Determinar los niveles de seguridad de los servicios como Responsable del Servicio."
                ),
              ]),
            ]),
            _c(
              "p",
              {
                staticStyle: {
                  "margin-top": "0cm",
                  "margin-right": "0cm",
                  "margin-bottom": "8.0pt",
                  "margin-left": "0cm",
                  "text-align": "justify",
                  "line-height": "110%",
                  "font-size": "15px",
                  "font-family": '"Arial Nova Light",sans-serif',
                },
              },
              [
                _vm._v(
                  " Responsabilidades del Responsable de Seguridad de la Información:"
                ),
              ]
            ),
            _c("ul", { staticStyle: { "list-style-type": "disc" } }, [
              _c("li", [
                _vm._v(
                  "Mantener el nivel adecuado de seguridad de la información y de los servicios prestados por los sistemas."
                ),
              ]),
              _c("li", [
                _vm._v(
                  "Realizar o promover las auditorías periódicas requeridas por el ENS."
                ),
              ]),
              _c("li", [
                _vm._v(
                  "Gestionar la formación y concienciación en materia de seguridad TIC."
                ),
              ]),
              _c("li", [
                _vm._v(
                  "Verificar que las medidas de seguridad existentes son adecuadas para las necesidades de la empresa."
                ),
              ]),
              _c("li", [
                _vm._v(
                  "Revisar, completar y aprobar toda la documentación relacionada con la seguridad del sistema."
                ),
              ]),
              _c("li", [
                _vm._v(
                  "Monitorizar el estado de seguridad del sistema mediante herramientas y mecanismos de auditoría."
                ),
              ]),
              _c("li", [
                _vm._v(
                  "Apoyar y supervisar la investigación de incidentes de seguridad desde su notificación hasta su resolución, emitiendo informes al Comité. "
                ),
              ]),
            ]),
            _c(
              "p",
              {
                staticStyle: {
                  "margin-top": "0cm",
                  "margin-right": "0cm",
                  "margin-bottom": "8.0pt",
                  "margin-left": "0cm",
                  "text-align": "justify",
                  "line-height": "110%",
                  "font-size": "15px",
                  "font-family": '"Arial Nova Light",sans-serif',
                },
              },
              [_vm._v(" Responsabilidades del Responsable del Sistema:")]
            ),
            _c("ul", { staticStyle: { "list-style-type": "disc" } }, [
              _c("li", [
                _vm._v(
                  "Gestionar el sistema durante todo su ciclo de vida, desde la especificación e instalación hasta el seguimiento de su funcionamiento."
                ),
              ]),
              _c("li", [
                _vm._v(
                  "Definir los criterios de uso y los servicios disponibles en el sistema."
                ),
              ]),
              _c("li", [
                _vm._v(
                  "Establecer las políticas de acceso de usuarios al sistema."
                ),
              ]),
              _c("li", [
                _vm._v(
                  "Aprobar los cambios que afecten a la seguridad del sistema."
                ),
              ]),
              _c("li", [
                _vm._v(
                  "Determinar la configuración autorizada de hardware y software y aprobar modificaciones importantes."
                ),
              ]),
              _c("li", [
                _vm._v(
                  "Realizar el análisis y gestión de riesgos del sistema."
                ),
              ]),
              _c("li", [
                _vm._v(
                  "Elaborar y aprobar la documentación de seguridad del sistema."
                ),
              ]),
              _c("li", [
                _vm._v(
                  "Categorizar el sistema según el ENS y determinar las medidas de seguridad aplicables."
                ),
              ]),
              _c("li", [
                _vm._v(
                  "Implantar y controlar las medidas específicas de seguridad del sistema."
                ),
              ]),
              _c("li", [
                _vm._v(
                  "Establecer planes de contingencia y emergencia, realizando ejercicios para familiarizar al personal."
                ),
              ]),
              _c("li", [
                _vm._v(
                  "Suspender el manejo de cierta información o la prestación de un servicio si detecta deficiencias graves."
                ),
              ]),
            ]),
            _c(
              "p",
              {
                staticStyle: {
                  "margin-top": "0cm",
                  "margin-right": "0cm",
                  "margin-bottom": "8.0pt",
                  "margin-left": "0cm",
                  "text-align": "justify",
                  "line-height": "110%",
                  "font-size": "15px",
                  "font-family": '"Arial Nova Light",sans-serif',
                },
              },
              [
                _vm._v(
                  " Responsabilidades del Administrador de Seguridad (Técnico TI):"
                ),
              ]
            ),
            _c("ul", { staticStyle: { "list-style-type": "disc" } }, [
              _c("li", [
                _vm._v(
                  "Implementar, gestionar y mantener las medidas de seguridad."
                ),
              ]),
              _c("li", [
                _vm._v(
                  "Gestionar, configurar y actualizar el hardware y software de seguridad, supervisando su funcionamiento."
                ),
              ]),
              _c("li", [
                _vm._v(
                  "Gestionar las autorizaciones y privilegios concedidos a los usuarios del sistema."
                ),
              ]),
              _c("li", [
                _vm._v(
                  "Aplicar los procedimientos de seguridad y verificar su cumplimiento."
                ),
              ]),
              _c("li", [
                _vm._v("Aprobar los cambios en la configuración de seguridad."),
              ]),
              _c("li", [
                _vm._v("Asegurar que los controles de seguridad se cumplen."),
              ]),
              _c("li", [
                _vm._v("Monitorizar el estado de seguridad del sistema."),
              ]),
              _c("li", [
                _vm._v(
                  "Informar al Responsable de Seguridad de la Información (CTO) sobre cualquier anomalía."
                ),
              ]),
              _c("li", [
                _vm._v(
                  "Colaborar en la investigación y resolución de incidentes de seguridad. "
                ),
              ]),
              _c("li", [
                _vm._v("Registrar y gestionar los incidentes de seguridad."),
              ]),
              _c("li", [
                _vm._v("Aislar incidentes para evitar su propagación."),
              ]),
              _c("li", [
                _vm._v(
                  "Tomar decisiones inmediatas si la información se ha visto comprometida."
                ),
              ]),
              _c("li", [
                _vm._v(
                  "Asegurar la integridad de los elementos críticos del sistema si su disponibilidad se ha visto afectada."
                ),
              ]),
              _c("li", [
                _vm._v(
                  "Mantener y recuperar la información almacenada por el sistema y sus servicios asociados."
                ),
              ]),
              _c("li", [
                _vm._v(
                  "Investigar incidentes para determinar el modo, medios, motivos y origen."
                ),
              ]),
            ]),
            _c(
              "p",
              {
                staticStyle: {
                  "margin-top": "0cm",
                  "margin-right": "0cm",
                  "margin-bottom": "8.0pt",
                  "margin-left": "0cm",
                  "text-align": "justify",
                  "line-height": "110%",
                  "font-size": "15px",
                  "font-family": '"Arial Nova Light",sans-serif',
                },
              },
              [_vm._v(" Responsabilidades de los Usuarios:")]
            ),
            _c(
              "ul",
              {
                staticClass: "decimal_type",
                staticStyle: { "list-style-type": "disc" },
              },
              [
                _c("li", [
                  _vm._v(
                    "Aplicar las medidas de seguridad descritas en las normativas internas."
                  ),
                ]),
                _c("li", [
                  _vm._v(
                    "Reportar cualquier incidente o anomalía de seguridad que detecten."
                  ),
                  _c(
                    "ol",
                    {
                      staticClass: "decimal_type",
                      staticStyle: { "list-style-type": "decimal" },
                      attrs: { start: "5" },
                    },
                    [
                      _c("li", [
                        _c(
                          "h2",
                          {
                            staticStyle: {
                              "margin-top": "18.0pt",
                              "margin-right": "0cm",
                              "margin-bottom": "6.0pt",
                              "margin-left": "28.9pt",
                              "text-align": "justify",
                              "text-indent": "-28.9pt",
                              "line-height": "110%",
                              "font-size": "19px",
                              "font-family": '"Arial Nova Light",sans-serif',
                              color: "#C00000",
                            },
                          },
                          [_vm._v(" Procedimiento de Designación")]
                        ),
                      ]),
                    ]
                  ),
                ]),
              ]
            ),
            _c(
              "p",
              {
                staticStyle: {
                  "margin-top": "0cm",
                  "margin-right": "0cm",
                  "margin-bottom": "8.0pt",
                  "margin-left": "0cm",
                  "text-align": "justify",
                  "line-height": "110%",
                  "font-size": "15px",
                  "font-family": '"Arial Nova Light",sans-serif',
                },
              },
              [
                _vm._v(
                  " El Responsable de Seguridad de la Información será nombrado por la Alta Dirección. El nombramiento se revisará cada 2 años o cuando el puesto quede vacante."
                ),
              ]
            ),
            _c(
              "p",
              {
                staticStyle: {
                  "margin-top": "0cm",
                  "margin-right": "0cm",
                  "margin-bottom": "8.0pt",
                  "margin-left": "0cm",
                  "text-align": "justify",
                  "line-height": "110%",
                  "font-size": "15px",
                  "font-family": '"Arial Nova Light",sans-serif',
                },
              },
              [
                _vm._v(
                  " El Responsable del Sistema será designado por la Alta Dirección, especificando sus funciones y responsabilidades dentro del marco establecido por esta Política."
                ),
              ]
            ),
            _c(
              "div",
              {
                staticStyle: {
                  "margin-top": "0cm",
                  "margin-right": "0cm",
                  "margin-bottom": "8.0pt",
                  "margin-left": "0cm",
                  "text-align": "justify",
                  "line-height": "110%",
                  "font-size": "15px",
                  "font-family": '"Arial Nova Light",sans-serif',
                },
              },
              [
                _c(
                  "ol",
                  {
                    staticStyle: {
                      "margin-bottom": "0cm",
                      "list-style-type": "decimal",
                    },
                    attrs: { start: "5" },
                  },
                  [
                    _c(
                      "li",
                      {
                        staticStyle: {
                          "margin-top": "0cm",
                          "margin-right": "0cm",
                          "margin-bottom": "8.0pt",
                          "margin-left": "0cm",
                          "text-align": "justify",
                          "line-height": "110%",
                          "font-size": "15px",
                          "font-family": '"Arial Nova Light",sans-serif',
                        },
                      },
                      [
                        _c(
                          "h2",
                          {
                            staticStyle: {
                              "margin-top": "18.0pt",
                              "margin-right": "0cm",
                              "margin-bottom": "6.0pt",
                              "margin-left": "28.9pt",
                              "text-align": "justify",
                              "text-indent": "-28.9pt",
                              "line-height": "110%",
                              "font-size": "19px",
                              "font-family": '"Arial Nova Light",sans-serif',
                              color: "#C00000",
                            },
                          },
                          [_vm._v(" Política de Seguridad de la Información")]
                        ),
                      ]
                    ),
                  ]
                ),
              ]
            ),
            _c(
              "p",
              {
                staticStyle: {
                  "margin-top": "0cm",
                  "margin-right": "0cm",
                  "margin-bottom": "8.0pt",
                  "margin-left": "0cm",
                  "text-align": "justify",
                  "line-height": "110%",
                  "font-size": "15px",
                  "font-family": '"Arial Nova Light",sans-serif',
                },
              },
              [
                _vm._v(
                  " Será misión del Comité de Seguridad TIC revisar anualmente esta Política de Seguridad de la Información y proponer su revisión o mantenimiento. La Política será aprobada por la Alta Dirección y difundida para que la conozcan todas las partes afectadas."
                ),
              ]
            ),
            _c(
              "div",
              {
                staticStyle: {
                  "margin-top": "0cm",
                  "margin-right": "0cm",
                  "margin-bottom": "8.0pt",
                  "margin-left": "0cm",
                  "text-align": "justify",
                  "line-height": "110%",
                  "font-size": "15px",
                  "font-family": '"Arial Nova Light",sans-serif',
                  border: "none",
                  "border-bottom": "solid #C00000 1.0pt",
                  padding: "0cm 0cm 1.0pt 0cm",
                },
              },
              [
                _c(
                  "h1",
                  {
                    staticStyle: {
                      "margin-top": "18.0pt",
                      "margin-right": "0cm",
                      "margin-bottom": "8.0pt",
                      "margin-left": "21.55pt",
                      "text-align": "justify",
                      "text-indent": "-21.55pt",
                      border: "none",
                      padding: "0cm",
                      "font-size": "24px",
                      "font-family": '"Arial Nova Light",sans-serif',
                      color: "#C00000",
                    },
                  },
                  [
                    _c(
                      "span",
                      { staticStyle: { "font-family": '"Arial Nova Light"' } },
                      [_vm._v("6     ")]
                    ),
                    _vm._v("Datos de Carácter Personal"),
                  ]
                ),
              ]
            ),
            _c(
              "p",
              {
                staticStyle: {
                  "margin-top": "0cm",
                  "margin-right": "0cm",
                  "margin-bottom": "8.0pt",
                  "margin-left": "0cm",
                  "text-align": "justify",
                  "line-height": "110%",
                  "font-size": "15px",
                  "font-family": '"Arial Nova Light",sans-serif',
                },
              },
              [
                _vm._v(
                  " La Entidad trata datos de carácter personal. Todos los sistemas de información de la Entidad se ajustarán a las medidas de seguridad requeridos por su análisis de riesgos y por la normativa para la naturaleza y finalidad de los datos de carácter personal recogidos en el mencionado manual y el la documentación de dicho sistema. "
                ),
              ]
            ),
            _c(
              "div",
              {
                staticStyle: {
                  "margin-top": "0cm",
                  "margin-right": "0cm",
                  "margin-bottom": "8.0pt",
                  "margin-left": "0cm",
                  "text-align": "justify",
                  "line-height": "110%",
                  "font-size": "15px",
                  "font-family": '"Arial Nova Light",sans-serif',
                  border: "none",
                  "border-bottom": "solid #C00000 1.0pt",
                  padding: "0cm 0cm 1.0pt 0cm",
                },
              },
              [
                _c(
                  "h1",
                  {
                    staticStyle: {
                      "margin-top": "18.0pt",
                      "margin-right": "0cm",
                      "margin-bottom": "8.0pt",
                      "margin-left": "21.55pt",
                      "text-align": "justify",
                      "text-indent": "-21.55pt",
                      border: "none",
                      padding: "0cm",
                      "font-size": "24px",
                      "font-family": '"Arial Nova Light",sans-serif',
                      color: "#C00000",
                    },
                  },
                  [
                    _c(
                      "span",
                      { staticStyle: { "font-family": '"Arial Nova Light"' } },
                      [_vm._v("7     ")]
                    ),
                    _vm._v("Gestión de Riesgos"),
                  ]
                ),
              ]
            ),
            _c(
              "p",
              {
                staticStyle: {
                  "margin-top": "0cm",
                  "margin-right": "0cm",
                  "margin-bottom": "8.0pt",
                  "margin-left": "0cm",
                  "text-align": "justify",
                  "line-height": "110%",
                  "font-size": "15px",
                  "font-family": '"Arial Nova Light",sans-serif',
                },
              },
              [
                _vm._v(
                  " Todos los sistemas sujetos a esta Política deberán realizar un análisis de riesgos, evaluando las amenazas y los riesgos a los que están expuestos. Este análisis se repetirá:"
                ),
              ]
            ),
            _c("ul", { staticStyle: { "list-style-type": "disc" } }, [
              _c("li", [
                _vm._v(
                  "Regularmente, al menos una vez al año cuando cambie la información manejada."
                ),
              ]),
              _c("li", [_vm._v("Cuando cambien los servicios prestados.")]),
              _c("li", [
                _vm._v("Cuando ocurra un incidente grave de seguridad."),
              ]),
              _c("li", [_vm._v("Cuando se reporten vulnerabilidades graves.")]),
            ]),
            _c(
              "p",
              {
                staticStyle: {
                  "margin-top": "0cm",
                  "margin-right": "0cm",
                  "margin-bottom": "8.0pt",
                  "margin-left": "0cm",
                  "text-align": "justify",
                  "line-height": "110%",
                  "font-size": "15px",
                  "font-family": '"Arial Nova Light",sans-serif',
                },
              },
              [
                _vm._v(
                  " Para la armonización de los análisis de riesgos, el Comité de Seguridad TIC establecerá una valoración de referencia para los diferentes tipos de información manejados y los diferentes servicios prestados. El Comité de Seguridad TIC dinamizará la disponibilidad de recursos para atender a las necesidades de seguridad de los diferentes sistemas, promoviendo inversiones de carácter horizontal."
                ),
              ]
            ),
            _c(
              "div",
              {
                staticStyle: {
                  "margin-top": "0cm",
                  "margin-right": "0cm",
                  "margin-bottom": "8.0pt",
                  "margin-left": "0cm",
                  "text-align": "justify",
                  "line-height": "110%",
                  "font-size": "15px",
                  "font-family": '"Arial Nova Light",sans-serif',
                  border: "none",
                  "border-bottom": "solid #C00000 1.0pt",
                  padding: "0cm 0cm 1.0pt 0cm",
                },
              },
              [
                _c(
                  "h1",
                  {
                    staticStyle: {
                      "margin-top": "18.0pt",
                      "margin-right": "0cm",
                      "margin-bottom": "8.0pt",
                      "margin-left": "21.55pt",
                      "text-align": "justify",
                      "text-indent": "-21.55pt",
                      border: "none",
                      padding: "0cm",
                      "font-size": "24px",
                      "font-family": '"Arial Nova Light",sans-serif',
                      color: "#C00000",
                    },
                  },
                  [
                    _c(
                      "span",
                      { staticStyle: { "font-family": '"Arial Nova Light"' } },
                      [_vm._v("8     ")]
                    ),
                    _vm._v("Obligaciones del Personal"),
                  ]
                ),
              ]
            ),
            _c(
              "p",
              {
                staticStyle: {
                  "margin-top": "0cm",
                  "margin-right": "0cm",
                  "margin-bottom": "8.0pt",
                  "margin-left": "0cm",
                  "text-align": "justify",
                  "line-height": "110%",
                  "font-size": "15px",
                  "font-family": '"Arial Nova Light",sans-serif',
                },
              },
              [
                _vm._v(
                  " Todos los miembros de la entidad tienen la obligación de conocer y cumplir esta Política de Seguridad de la Información y la Normativa de Seguridad, siendo responsabilidad del Comité de Seguridad TIC disponer los medios necesarios para que la información llegue a los afectados."
                ),
              ]
            ),
            _c(
              "p",
              {
                staticStyle: {
                  "margin-top": "0cm",
                  "margin-right": "0cm",
                  "margin-bottom": "8.0pt",
                  "margin-left": "0cm",
                  "text-align": "justify",
                  "line-height": "110%",
                  "font-size": "15px",
                  "font-family": '"Arial Nova Light",sans-serif',
                },
              },
              [
                _vm._v(
                  " Todos los miembros de la entidad atenderán a una sesión de concienciación en materia de seguridad TIC al menos una vez al año. Se establecerá un programa de concienciación continua para atender a todos los miembros de la entidad, en particular a los de nueva incorporación."
                ),
              ]
            ),
            _c(
              "p",
              {
                staticStyle: {
                  "margin-top": "0cm",
                  "margin-right": "0cm",
                  "margin-bottom": "8.0pt",
                  "margin-left": "0cm",
                  "text-align": "justify",
                  "line-height": "110%",
                  "font-size": "15px",
                  "font-family": '"Arial Nova Light",sans-serif',
                },
              },
              [
                _vm._v(
                  " Las personas con responsabilidad en el uso, operación o administración de sistemas TIC recibirán formación para el manejo seguro de los sistemas en la medida en que la necesiten para realizar su trabajo. La formación será obligatoria antes de asumir una responsabilidad, tanto si es su primera asignación o si se trata de un cambio de puesto de trabajo o de responsabilidades en el mismo."
                ),
              ]
            ),
            _c(
              "div",
              {
                staticStyle: {
                  "margin-top": "0cm",
                  "margin-right": "0cm",
                  "margin-bottom": "8.0pt",
                  "margin-left": "0cm",
                  "text-align": "justify",
                  "line-height": "110%",
                  "font-size": "15px",
                  "font-family": '"Arial Nova Light",sans-serif',
                  border: "none",
                  "border-bottom": "solid #C00000 1.0pt",
                  padding: "0cm 0cm 1.0pt 0cm",
                },
              },
              [
                _c(
                  "h1",
                  {
                    staticStyle: {
                      "margin-top": "18.0pt",
                      "margin-right": "0cm",
                      "margin-bottom": "8.0pt",
                      "margin-left": "21.55pt",
                      "text-align": "justify",
                      "text-indent": "-21.55pt",
                      border: "none",
                      padding: "0cm",
                      "font-size": "24px",
                      "font-family": '"Arial Nova Light",sans-serif',
                      color: "#C00000",
                    },
                  },
                  [
                    _c(
                      "span",
                      { staticStyle: { "font-family": '"Arial Nova Light"' } },
                      [_vm._v("9     ")]
                    ),
                    _vm._v("Terceras Partes"),
                  ]
                ),
              ]
            ),
            _c(
              "p",
              {
                staticStyle: {
                  "margin-top": "0cm",
                  "margin-right": "0cm",
                  "margin-bottom": "8.0pt",
                  "margin-left": "0cm",
                  "text-align": "justify",
                  "line-height": "110%",
                  "font-size": "15px",
                  "font-family": '"Arial Nova Light",sans-serif',
                },
              },
              [
                _vm._v(
                  " Cuando la Entidad preste servicios a otros organismos o maneje información de otros organismos, se les hará partícipes de esta Política de Seguridad de la Información, se establecerán canales para reporte y coordinación de los respectivos Comités de Seguridad TIC y se establecerán procedimientos de actuación para la reacción ante incidentes de seguridad."
                ),
              ]
            ),
            _c(
              "p",
              {
                staticStyle: {
                  "margin-top": "0cm",
                  "margin-right": "0cm",
                  "margin-bottom": "8.0pt",
                  "margin-left": "0cm",
                  "text-align": "justify",
                  "line-height": "110%",
                  "font-size": "15px",
                  "font-family": '"Arial Nova Light",sans-serif',
                },
              },
              [
                _vm._v(
                  " Cuando la Entidad utilice servicios de terceros o ceda información a terceros, se les hará partícipes de esta Política de Seguridad y de la Normativa de Seguridad que ataña a dichos servicios o información. Dicha tercera parte quedará sujeta a las obligaciones establecidas en dicha normativa, pudiendo desarrollar sus propios procedimientos operativos para satisfacerla. Se establecerán procedimientos específicos de reporte y resolución de incidencias. Se garantizará que el personal de terceros está adecuadamente concienciado en materia de seguridad, al menos al mismo nivel que el establecido en esta Política. "
                ),
              ]
            ),
            _c(
              "p",
              {
                staticStyle: {
                  "margin-top": "0cm",
                  "margin-right": "0cm",
                  "margin-bottom": "8.0pt",
                  "margin-left": "0cm",
                  "text-align": "justify",
                  "line-height": "110%",
                  "font-size": "15px",
                  "font-family": '"Arial Nova Light",sans-serif',
                },
              },
              [
                _vm._v(
                  " Cuando algún aspecto de la Política no pueda ser satisfecho por una tercera parte según se requiere en los párrafos anteriores, se requerirá un informe del Responsable de Seguridad que precise los riesgos en que se incurre y la forma de tratarlos. Se requerirá la aprobación de este informe por los responsables de la información y los servicios afectados antes de seguir adelante."
                ),
              ]
            ),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }