<template>
    <default-layout>
        <div class="spainhn-solid-red-background d-flex" style="min-height: 5vh; width:100%">
            <div class="d-flex ps-5 my-auto container">
                <p class="my-auto pb-0 mb-0 spainhn-small-text" role="button" @click="goToCastillosYPalacios">Spain
                    Heritage Network >> </p>
                <p class="my-auto pb-0 mb-0 ms-2 spainhn-small-text">POLÍTICA DE SEGURIDAD DE LA INFORMACIÓN</p>
            </div>
        </div>
        <div class="container-fluid legal-main px-lx-5 px-1">
            <div class="container-fluid mb-5">
                <div class="row text-start margen-estandard px-lg-5">
                    <div class="d-flex flex-column pt-3 col-12 px-xl-5">
                        <p
                            style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;line-height:110%;font-size:15px;font-family:"Arial Nova Light",sans-serif;'>
                            <strong><span style="font-size:24px;line-height:110%;">Pol&iacute;tica de Seguridad de la
                                    Informaci&oacute;n ENS</span></strong></p>
                        <div
                            style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;line-height:110%;font-size:15px;font-family:"Arial Nova Light",sans-serif;border:none;border-bottom:solid #C00000 1.0pt;padding:0cm 0cm 1.0pt 0cm;'>
                            <h1
                                style='margin-top:18.0pt;margin-right:0cm;margin-bottom:8.0pt;margin-left:21.55pt;text-align:justify;text-indent:-21.55pt;border:none;padding:0cm;font-size:24px;font-family:"Arial Nova Light",sans-serif;color:#C00000;'>
                                <span style='font-family:"Arial Nova Light";'>1&nbsp; &nbsp;
                                    &nbsp;</span>INTRODUCCI&Oacute;N</h1>
                        </div>
                        <p
                            style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;line-height:110%;font-size:15px;font-family:"Arial Nova Light",sans-serif;'>
                            Spain Heritage Tours SL depende de los sistemas TIC (Tecnolog&iacute;as de
                            Informaci&oacute;n y Comunicaciones) para alcanzar sus objetivos. Estos sistemas deben ser
                            administrados con diligencia, tomando las medidas adecuadas para protegerlos frente a
                            da&ntilde;os accidentales o deliberados que puedan afectar a la disponibilidad, integridad o
                            confidencialidad de la informaci&oacute;n tratada o los servicios prestados.</p>
                        <p
                            style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;line-height:110%;font-size:15px;font-family:"Arial Nova Light",sans-serif;'>
                            El objetivo de la seguridad de la informaci&oacute;n es garantizar la calidad de la
                            informaci&oacute;n y la prestaci&oacute;n continuada de los servicios, actuando
                            preventivamente, supervisando la actividad diaria y reaccionando con presteza a los
                            incidentes.</p>
                        <p
                            style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;line-height:110%;font-size:15px;font-family:"Arial Nova Light",sans-serif;'>
                            Los sistemas TIC deben estar protegidos contra amenazas de r&aacute;pida evoluci&oacute;n
                            con potencial para incidir en la confidencialidad, integridad, disponibilidad, uso previsto
                            y valor de la informaci&oacute;n y los servicios. Para defenderse de estas amenazas, se
                            requiere una estrategia que se adapte a los cambios en las condiciones del entorno para
                            garantizar la prestaci&oacute;n continua de los servicios. Esto implica que los
                            departamentos deben aplicar las medidas m&iacute;nimas de seguridad exigidas por el Esquema
                            Nacional de Seguridad, as&iacute; como realizar un seguimiento continuo de los niveles de
                            prestaci&oacute;n de servicios, seguir y analizar las vulnerabilidades reportadas, y
                            preparar una respuesta efectiva a los incidentes para garantizar la continuidad de los
                            servicios prestados.</p>
                        <p
                            style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;line-height:110%;font-size:15px;font-family:"Arial Nova Light",sans-serif;'>
                            Los diferentes departamentos deben cerciorarse de que la seguridad TIC es una parte integral
                            de cada etapa del ciclo de vida del sistema, desde su concepci&oacute;n hasta su retirada de
                            servicio, pasando por las decisiones de desarrollo o adquisici&oacute;n y las actividades de
                            explotaci&oacute;n. Los requisitos de seguridad y las necesidades de financiaci&oacute;n,
                            deben ser identificados e incluidos en la planificaci&oacute;n, en la solicitud de ofertas,
                            y en pliegos de licitaci&oacute;n para proyectos de TIC.</p>
                        <p
                            style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;line-height:110%;font-size:15px;font-family:"Arial Nova Light",sans-serif;'>
                            Los departamentos deben estar preparados para prevenir, detectar, reaccionar y recuperarse
                            de incidentes, de acuerdo al Art&iacute;culo 7 del ENS (Art&iacute;culo 8.
                            Prevenci&oacute;n, detecci&oacute;n, respuesta y conservaci&oacute;n. Real Decreto 311/2022,
                            de 3 de mayo, por el que se regula el Esquema Nacional de Seguridad.)</p>
                        <div
                            style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;line-height:110%;font-size:15px;font-family:"Arial Nova Light",sans-serif;'>
                            <ol style="margin-bottom:0cm;list-style-type: decimal;">
                                <li
                                    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;line-height:110%;font-size:15px;font-family:"Arial Nova Light",sans-serif;'>
                                    <h2
                                        style='margin-top:18.0pt;margin-right:0cm;margin-bottom:6.0pt;margin-left:28.9pt;text-align:justify;text-indent:-28.9pt;line-height:110%;font-size:19px;font-family:"Arial Nova Light",sans-serif;color:#C00000;'>
                                        PREVENCI&Oacute;N</h2>
                                </li>
                            </ol>
                        </div>
                        <p
                            style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;line-height:110%;font-size:15px;font-family:"Arial Nova Light",sans-serif;'>
                            Los departamentos deben evitar, o al menos prevenir en la medida de lo posible, que la
                            informaci&oacute;n o los servicios se vean perjudicados por incidentes de seguridad. Para
                            ello los departamentos deben implementar las medidas m&iacute;nimas de seguridad
                            determinadas por el ENS, as&iacute; como cualquier control adicional identificado a
                            trav&eacute;s de una evaluaci&oacute;n de amenazas y riesgos. Estos controles, y los roles y
                            responsabilidades de seguridad de todo el personal, deben estar claramente definidos y
                            documentados. Para garantizar el cumplimiento de la pol&iacute;tica, los departamentos
                            deben: Autorizar los sistemas antes de entrar en operaci&oacute;n.</p>
                        <ul class="decimal_type" style="list-style-type: disc;">
                            <li>Evaluar regularmente la seguridad, incluyendo evaluaciones de los cambios de
                                configuraci&oacute;n realizados de forma rutinaria.</li>
                            <li>Solicitar la revisi&oacute;n peri&oacute;dica por parte de terceros con el fin de
                                obtener una evaluaci&oacute;n independiente.<ol class="decimal_type"
                                    style="list-style-type: decimal;">
                                    <li>
                                        <h2
                                            style='margin-top:18.0pt;margin-right:0cm;margin-bottom:6.0pt;margin-left:28.9pt;text-align:justify;text-indent:-28.9pt;line-height:110%;font-size:19px;font-family:"Arial Nova Light",sans-serif;color:#C00000;'>
                                            DETECCI&Oacute;N</h2>
                                    </li>
                                </ol>
                            </li>
                        </ul>
                        <p
                            style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;line-height:110%;font-size:15px;font-family:"Arial Nova Light",sans-serif;'>
                            Dado que los servicios se puede degradar r&aacute;pidamente debido a incidentes, que van
                            desde una simple desaceleraci&oacute;n hasta su detenci&oacute;n, los servicios deben
                            monitorizar la operaci&oacute;n de manera continua para detectar anomal&iacute;as en los
                            niveles de prestaci&oacute;n de los servicios y actuar en consecuencia seg&uacute;n lo
                            establecido en el Art&iacute;culo 10 del ENS. (Art&iacute;culo 9. Vigilancia continua y
                            Reevaluaci&oacute;n peri&oacute;dica).</p>
                        <p
                            style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;line-height:110%;font-size:15px;font-family:"Arial Nova Light",sans-serif;'>
                            La monitorizaci&oacute;n es especialmente relevante cuando se establecen l&iacute;neas de
                            defensa de acuerdo con el Art&iacute;culo 9 del ENS. (Art&iacute;culo 8. Existencia de
                            L&iacute;neas de defensa).</p>
                        <p
                            style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;line-height:110%;font-size:15px;font-family:"Arial Nova Light",sans-serif;'>
                            Se establecer&aacute;n mecanismos de detecci&oacute;n, an&aacute;lisis y reporte que lleguen
                            a los responsables regularmente y cuando se produce una desviaci&oacute;n significativa de
                            los par&aacute;metros que se hayan preestablecido como normales.</p>
                        <div
                            style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;line-height:110%;font-size:15px;font-family:"Arial Nova Light",sans-serif;'>
                            <ol style="margin-bottom:0cm;list-style-type: decimal;">
                                <li
                                    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;line-height:110%;font-size:15px;font-family:"Arial Nova Light",sans-serif;'>
                                    <h2
                                        style='margin-top:18.0pt;margin-right:0cm;margin-bottom:6.0pt;margin-left:28.9pt;text-align:justify;text-indent:-28.9pt;line-height:110%;font-size:19px;font-family:"Arial Nova Light",sans-serif;color:#C00000;'>
                                        RESPUESTA</h2>
                                </li>
                            </ol>
                        </div>
                        <p
                            style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;line-height:110%;font-size:15px;font-family:"Arial Nova Light",sans-serif;'>
                            La Entidad ha establecido mecanismos para responder eficazmente a los incidentes de
                            seguridad.</p>
                        <p
                            style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;line-height:110%;font-size:15px;font-family:"Arial Nova Light",sans-serif;'>
                            Se ha designado un punto de contacto para las comunicaciones con respecto a incidentes
                            detectados en otros departamentos o en otros organismos.</p>
                        <p
                            style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;line-height:110%;font-size:15px;font-family:"Arial Nova Light",sans-serif;'>
                            Se han establecido protocolos para el intercambio de informaci&oacute;n relacionada con el
                            incidente. Esto incluye comunicaciones, en ambos sentidos, con los Equipos de Respuesta a
                            Emergencias (CERT).</p>
                        <div
                            style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;line-height:110%;font-size:15px;font-family:"Arial Nova Light",sans-serif;'>
                            <ol style="margin-bottom:0cm;list-style-type: decimal;">
                                <li
                                    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;line-height:110%;font-size:15px;font-family:"Arial Nova Light",sans-serif;'>
                                    <h2
                                        style='margin-top:18.0pt;margin-right:0cm;margin-bottom:6.0pt;margin-left:28.9pt;text-align:justify;text-indent:-28.9pt;line-height:110%;font-size:19px;font-family:"Arial Nova Light",sans-serif;color:#C00000;'>
                                        RECUPERACI&Oacute;N</h2>
                                </li>
                            </ol>
                        </div>
                        <p
                            style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;line-height:110%;font-size:15px;font-family:"Arial Nova Light",sans-serif;'>
                            Para garantizar la disponibilidad de los servicios cr&iacute;ticos, la entidad ha
                            desarrollado planes de continuidad de los sistemas TIC como parte de su plan general de
                            continuidad de negocio y actividades de recuperaci&oacute;n.</p>
                        <div
                            style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;line-height:110%;font-size:15px;font-family:"Arial Nova Light",sans-serif;border:none;border-bottom:solid #C00000 1.0pt;padding:0cm 0cm 1.0pt 0cm;'>
                            <h1
                                style='margin-top:18.0pt;margin-right:0cm;margin-bottom:8.0pt;margin-left:21.55pt;text-align:justify;text-indent:-21.55pt;border:none;padding:0cm;font-size:24px;font-family:"Arial Nova Light",sans-serif;color:#C00000;'>
                                <span style='font-family:"Arial Nova Light";'>2&nbsp; &nbsp; &nbsp;</span>ALCANCE</h1>
                        </div>
                        <p
                            style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;line-height:110%;font-size:15px;font-family:"Arial Nova Light",sans-serif;'>
                            Esta pol&iacute;tica se aplica a todos los sistemas TIC de la entidad y a todos los miembros
                            de la organizaci&oacute;n, implicados en Servicios y Proyectos destinados al sector
                            p&uacute;blico, que requieran la aplicaci&oacute;n de ENS, sin excepciones.</p>
                        <div
                            style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;line-height:110%;font-size:15px;font-family:"Arial Nova Light",sans-serif;border:none;border-bottom:solid #C00000 1.0pt;padding:0cm 0cm 1.0pt 0cm;'>
                            <h1
                                style='margin-top:18.0pt;margin-right:0cm;margin-bottom:8.0pt;margin-left:21.55pt;text-align:justify;text-indent:-21.55pt;border:none;padding:0cm;font-size:24px;font-family:"Arial Nova Light",sans-serif;color:#C00000;'>
                                <span style='font-family:"Arial Nova Light";'>3&nbsp; &nbsp; &nbsp;</span>MISI&Oacute;N
                            </h1>
                        </div>
                        <p
                            style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;line-height:110%;font-size:15px;font-family:"Arial Nova Light",sans-serif;'>
                            Los principales objetivos que se persiguen son:</p>
                        <ul style="list-style-type: disc;">
                            <li>Fomentar la relaci&oacute;n electr&oacute;nica del usuario con la Entidad o la de sus
                                Clientes.</li>
                            <li>Reducir tiempos de espera de atenci&oacute;n al usuario.</li>
                            <li>Acortar tiempos de espera en la resoluci&oacute;n de tr&aacute;mites solicitados por el
                                usuario.</li>
                            <li>Desarrollar un sistema de gesti&oacute;n de informaci&oacute;n documental que facilite
                                un r&aacute;pido acceso del personal del servicio a la informaci&oacute;n solicitada por
                                el usuario.</li>
                        </ul>
                        <div
                            style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;line-height:110%;font-size:15px;font-family:"Arial Nova Light",sans-serif;border:none;border-bottom:solid #C00000 1.0pt;padding:0cm 0cm 1.0pt 0cm;'>
                            <h1
                                style='margin-top:18.0pt;margin-right:0cm;margin-bottom:8.0pt;margin-left:21.55pt;text-align:justify;text-indent:-21.55pt;border:none;padding:0cm;font-size:24px;font-family:"Arial Nova Light",sans-serif;color:#C00000;'>
                                <span style='font-family:"Arial Nova Light";'>4&nbsp; &nbsp; &nbsp;</span>MARCO
                                NORMATIVO</h1>
                        </div>
                        <p
                            style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;line-height:110%;font-size:15px;font-family:"Arial Nova Light",sans-serif;'>
                            Esta pol&iacute;tica se enmarca en la siguiente legislaci&oacute;n:</p>
                        <ol style="list-style-type: decimal;">
                            <li>RD 311/2022 Real Decreto 311/2022, de 3 de mayo, por el que se regula el Esquema
                                Nacional de Seguridad. BOE de 04 de mayo de 2022.</li>
                            <li>Ley 30/1992, de 26 de noviembre, de R&eacute;gimen Jur&iacute;dico de las
                                Administraciones P&uacute;blicas y del Procedimiento Administrativo Com&uacute;n.</li>
                            <li>Ley 40/2015, de 1 de octubre, de R&eacute;gimen Jur&iacute;dico del Sector
                                P&uacute;blico.</li>
                            <li>Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo, de 27 de abril de 2016.
                                relativo a la protecci&oacute;n de las personas f&iacute;sicas en lo que respecta al
                                tratamiento de datos personales y a la libre circulaci&oacute;n de estos datos.</li>
                            <li>Ley Org&aacute;nica 3/2018, de 5 de diciembre, de Protecci&oacute;n de Datos Personales
                                y garant&iacute;a de los derechos digitales.</li>
                            <li>Ley 11/2007, de 22 de junio, de acceso electr&oacute;nico de los ciudadanos a los
                                Servicios P&uacute;blicos.</li>
                        </ol>
                        <div
                            style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;line-height:110%;font-size:15px;font-family:"Arial Nova Light",sans-serif;border:none;border-bottom:solid #C00000 1.0pt;padding:0cm 0cm 1.0pt 0cm;'>
                            <h1
                                style='margin-top:18.0pt;margin-right:0cm;margin-bottom:8.0pt;margin-left:21.55pt;text-align:justify;text-indent:-21.55pt;border:none;padding:0cm;font-size:24px;font-family:"Arial Nova Light",sans-serif;color:#C00000;'>
                                <span style='font-family:"Arial Nova Light";'>5&nbsp; &nbsp;
                                    &nbsp;</span>Organizaci&oacute;n de la Seguridad</h1>
                        </div>
                        <div
                            style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;line-height:110%;font-size:15px;font-family:"Arial Nova Light",sans-serif;'>
                            <ol start="5" style="margin-bottom:0cm;list-style-type: decimal;">
                                <li
                                    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;line-height:110%;font-size:15px;font-family:"Arial Nova Light",sans-serif;'>
                                    <h2
                                        style='margin-top:18.0pt;margin-right:0cm;margin-bottom:6.0pt;margin-left:28.9pt;text-align:justify;text-indent:-28.9pt;line-height:110%;font-size:19px;font-family:"Arial Nova Light",sans-serif;color:#C00000;'>
                                        Comit&eacute;: Funciones y Responsabilidades</h2>
                                </li>
                            </ol>
                        </div>
                        <p
                            style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;line-height:110%;font-size:15px;font-family:"Arial Nova Light",sans-serif;'>
                            El Comit&eacute; de Seguridad TIC estar&aacute; formado por:</p>
                        <ul style="list-style-type: disc;">
                            <li>El Presidente o Director de Desarrollo de Red: actuar&aacute; como Alta
                                Direcci&oacute;n, Titular, Responsable del Tratamiento, Responsable de la
                                Informaci&oacute;n (RINFO) y Responsable del Servicio (RSER).</li>
                            <li>El Director de Tecnolog&iacute;a: ser&aacute; el Responsable de Seguridad de la
                                Informaci&oacute;n (RSEG) y el Responsable del Sistema (RSIS).</li>
                            <li>El T&eacute;cnico TI: desempe&ntilde;ar&aacute; el rol de Administrador de Seguridad
                                (AS).</li>
                        </ul>
                        <p
                            style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;line-height:110%;font-size:15px;font-family:"Arial Nova Light",sans-serif;'>
                            El CTO actuar&aacute; como Secretario del Comit&eacute; de Seguridad TIC,
                            encarg&aacute;ndose de convocar las reuniones y levantar acta de las mismas.</p>
                        <p
                            style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;line-height:110%;font-size:15px;font-family:"Arial Nova Light",sans-serif;'>
                            El Comit&eacute; de Seguridad TIC reportar&aacute; a la Alta Direcci&oacute;n.</p>
                        <p
                            style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;line-height:110%;font-size:15px;font-family:"Arial Nova Light",sans-serif;'>
                            El Comit&eacute; de Seguridad TIC tendr&aacute; las siguientes funciones:</p>
                        <ul class="decimal_type" style="list-style-type: disc;">
                            <li>Coordinar y aprobar las acciones en materia de seguridad de la informaci&oacute;n.</li>
                            <li>Impulsar la cultura en seguridad de la informaci&oacute;n.</li>
                            <li>Participar en la categorizaci&oacute;n de los sistemas y el an&aacute;lisis de riesgos.
                            </li>
                            <li>Revisar la documentaci&oacute;n relacionada con la seguridad del sistema.</li>
                            <li>Resolver discrepancias y problemas que puedan surgir en la gesti&oacute;n de la
                                seguridad.<ol class="decimal_type" start="5" style="list-style-type: decimal;">
                                    <li>
                                        <h2
                                            style='margin-top:18.0pt;margin-right:0cm;margin-bottom:6.0pt;margin-left:28.9pt;text-align:justify;text-indent:-28.9pt;line-height:110%;font-size:19px;font-family:"Arial Nova Light",sans-serif;color:#C00000;'>
                                            Roles: Funciones y Responsabilidades</h2>
                                    </li>
                                </ol>
                            </li>
                        </ul>
                        <p
                            style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;line-height:110%;font-size:15px;font-family:"Arial Nova Light",sans-serif;'>
                            Responsabilidades de la Alta Direcci&oacute;n (Presidente o Director de Desarrollo de Red):
                        </p>
                        <ul style="list-style-type: disc;">
                            <li>Fijar los objetivos estrat&eacute;gicos en materia de seguridad de la
                                informaci&oacute;n.</li>
                            <li>Organizar adecuadamente los recursos y dirigir la actividad relacionada con la
                                seguridad.</li>
                            <li>Aprobar la Pol&iacute;tica de Seguridad de la Informaci&oacute;n y, en su caso, la
                                Pol&iacute;tica de Protecci&oacute;n de Datos.</li>
                            <li>Facilitar los recursos adecuados para alcanzar los objetivos propuestos y velar por su
                                cumplimiento.</li>
                            <li>Actuar como Responsable del Tratamiento de Datos Personales.</li>
                            <li>Asumir el rol de Responsable de la Informaci&oacute;n, teniendo la responsabilidad
                                &uacute;ltima del uso y protecci&oacute;n de la informaci&oacute;n.</li>
                            <li>Determinar los niveles de seguridad de los servicios como Responsable del Servicio.</li>
                        </ul>
                        <p
                            style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;line-height:110%;font-size:15px;font-family:"Arial Nova Light",sans-serif;'>
                            Responsabilidades del Responsable de Seguridad de la Informaci&oacute;n:</p>
                        <ul style="list-style-type: disc;">
                            <li>Mantener el nivel adecuado de seguridad de la informaci&oacute;n y de los servicios
                                prestados por los sistemas.</li>
                            <li>Realizar o promover las auditor&iacute;as peri&oacute;dicas requeridas por el ENS.</li>
                            <li>Gestionar la formaci&oacute;n y concienciaci&oacute;n en materia de seguridad TIC.</li>
                            <li>Verificar que las medidas de seguridad existentes son adecuadas para las necesidades de
                                la empresa.</li>
                            <li>Revisar, completar y aprobar toda la documentaci&oacute;n relacionada con la seguridad
                                del sistema.</li>
                            <li>Monitorizar el estado de seguridad del sistema mediante herramientas y mecanismos de
                                auditor&iacute;a.</li>
                            <li>Apoyar y supervisar la investigaci&oacute;n de incidentes de seguridad desde su
                                notificaci&oacute;n hasta su resoluci&oacute;n, emitiendo informes al Comit&eacute;.
                            </li>
                        </ul>
                        <p
                            style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;line-height:110%;font-size:15px;font-family:"Arial Nova Light",sans-serif;'>
                            Responsabilidades del Responsable del Sistema:</p>
                        <ul style="list-style-type: disc;">
                            <li>Gestionar el sistema durante todo su ciclo de vida, desde la especificaci&oacute;n e
                                instalaci&oacute;n hasta el seguimiento de su funcionamiento.</li>
                            <li>Definir los criterios de uso y los servicios disponibles en el sistema.</li>
                            <li>Establecer las pol&iacute;ticas de acceso de usuarios al sistema.</li>
                            <li>Aprobar los cambios que afecten a la seguridad del sistema.</li>
                            <li>Determinar la configuraci&oacute;n autorizada de hardware y software y aprobar
                                modificaciones importantes.</li>
                            <li>Realizar el an&aacute;lisis y gesti&oacute;n de riesgos del sistema.</li>
                            <li>Elaborar y aprobar la documentaci&oacute;n de seguridad del sistema.</li>
                            <li>Categorizar el sistema seg&uacute;n el ENS y determinar las medidas de seguridad
                                aplicables.</li>
                            <li>Implantar y controlar las medidas espec&iacute;ficas de seguridad del sistema.</li>
                            <li>Establecer planes de contingencia y emergencia, realizando ejercicios para familiarizar
                                al personal.</li>
                            <li>Suspender el manejo de cierta informaci&oacute;n o la prestaci&oacute;n de un servicio
                                si detecta deficiencias graves.</li>
                        </ul>
                        <p
                            style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;line-height:110%;font-size:15px;font-family:"Arial Nova Light",sans-serif;'>
                            Responsabilidades del Administrador de Seguridad (T&eacute;cnico TI):</p>
                        <ul style="list-style-type: disc;">
                            <li>Implementar, gestionar y mantener las medidas de seguridad.</li>
                            <li>Gestionar, configurar y actualizar el hardware y software de seguridad, supervisando su
                                funcionamiento.</li>
                            <li>Gestionar las autorizaciones y privilegios concedidos a los usuarios del sistema.</li>
                            <li>Aplicar los procedimientos de seguridad y verificar su cumplimiento.</li>
                            <li>Aprobar los cambios en la configuraci&oacute;n de seguridad.</li>
                            <li>Asegurar que los controles de seguridad se cumplen.</li>
                            <li>Monitorizar el estado de seguridad del sistema.</li>
                            <li>Informar al Responsable de Seguridad de la Informaci&oacute;n (CTO) sobre cualquier
                                anomal&iacute;a.</li>
                            <li>Colaborar en la investigaci&oacute;n y resoluci&oacute;n de incidentes de seguridad.
                            </li>
                            <li>Registrar y gestionar los incidentes de seguridad.</li>
                            <li>Aislar incidentes para evitar su propagaci&oacute;n.</li>
                            <li>Tomar decisiones inmediatas si la informaci&oacute;n se ha visto comprometida.</li>
                            <li>Asegurar la integridad de los elementos cr&iacute;ticos del sistema si su disponibilidad
                                se ha visto afectada.</li>
                            <li>Mantener y recuperar la informaci&oacute;n almacenada por el sistema y sus servicios
                                asociados.</li>
                            <li>Investigar incidentes para determinar el modo, medios, motivos y origen.</li>
                        </ul>
                        <p
                            style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;line-height:110%;font-size:15px;font-family:"Arial Nova Light",sans-serif;'>
                            Responsabilidades de los Usuarios:</p>
                        <ul class="decimal_type" style="list-style-type: disc;">
                            <li>Aplicar las medidas de seguridad descritas en las normativas internas.</li>
                            <li>Reportar cualquier incidente o anomal&iacute;a de seguridad que detecten.<ol
                                    class="decimal_type" start="5" style="list-style-type: decimal;">
                                    <li>
                                        <h2
                                            style='margin-top:18.0pt;margin-right:0cm;margin-bottom:6.0pt;margin-left:28.9pt;text-align:justify;text-indent:-28.9pt;line-height:110%;font-size:19px;font-family:"Arial Nova Light",sans-serif;color:#C00000;'>
                                            Procedimiento de Designaci&oacute;n</h2>
                                    </li>
                                </ol>
                            </li>
                        </ul>
                        <p
                            style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;line-height:110%;font-size:15px;font-family:"Arial Nova Light",sans-serif;'>
                            El Responsable de Seguridad de la Informaci&oacute;n ser&aacute; nombrado por la Alta
                            Direcci&oacute;n. El nombramiento se revisar&aacute; cada 2 a&ntilde;os o cuando el puesto
                            quede vacante.</p>
                        <p
                            style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;line-height:110%;font-size:15px;font-family:"Arial Nova Light",sans-serif;'>
                            El Responsable del Sistema ser&aacute; designado por la Alta Direcci&oacute;n, especificando
                            sus funciones y responsabilidades dentro del marco establecido por esta Pol&iacute;tica.</p>
                        <div
                            style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;line-height:110%;font-size:15px;font-family:"Arial Nova Light",sans-serif;'>
                            <ol start="5" style="margin-bottom:0cm;list-style-type: decimal;">
                                <li
                                    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;line-height:110%;font-size:15px;font-family:"Arial Nova Light",sans-serif;'>
                                    <h2
                                        style='margin-top:18.0pt;margin-right:0cm;margin-bottom:6.0pt;margin-left:28.9pt;text-align:justify;text-indent:-28.9pt;line-height:110%;font-size:19px;font-family:"Arial Nova Light",sans-serif;color:#C00000;'>
                                        Pol&iacute;tica de Seguridad de la Informaci&oacute;n</h2>
                                </li>
                            </ol>
                        </div>
                        <p
                            style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;line-height:110%;font-size:15px;font-family:"Arial Nova Light",sans-serif;'>
                            Ser&aacute; misi&oacute;n del Comit&eacute; de Seguridad TIC revisar anualmente esta
                            Pol&iacute;tica de Seguridad de la Informaci&oacute;n y proponer su revisi&oacute;n o
                            mantenimiento. La Pol&iacute;tica ser&aacute; aprobada por la Alta Direcci&oacute;n y
                            difundida para que la conozcan todas las partes afectadas.</p>
                        <div
                            style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;line-height:110%;font-size:15px;font-family:"Arial Nova Light",sans-serif;border:none;border-bottom:solid #C00000 1.0pt;padding:0cm 0cm 1.0pt 0cm;'>
                            <h1
                                style='margin-top:18.0pt;margin-right:0cm;margin-bottom:8.0pt;margin-left:21.55pt;text-align:justify;text-indent:-21.55pt;border:none;padding:0cm;font-size:24px;font-family:"Arial Nova Light",sans-serif;color:#C00000;'>
                                <span style='font-family:"Arial Nova Light";'>6&nbsp; &nbsp; &nbsp;</span>Datos de
                                Car&aacute;cter Personal</h1>
                        </div>
                        <p
                            style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;line-height:110%;font-size:15px;font-family:"Arial Nova Light",sans-serif;'>
                            La Entidad trata datos de car&aacute;cter personal. Todos los sistemas de informaci&oacute;n
                            de la Entidad se ajustar&aacute;n a las medidas de seguridad requeridos por su
                            an&aacute;lisis de riesgos y por la normativa para la naturaleza y finalidad de los datos de
                            car&aacute;cter personal recogidos en el mencionado manual y el la documentaci&oacute;n de
                            dicho sistema.&nbsp;</p>
                        <div
                            style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;line-height:110%;font-size:15px;font-family:"Arial Nova Light",sans-serif;border:none;border-bottom:solid #C00000 1.0pt;padding:0cm 0cm 1.0pt 0cm;'>
                            <h1
                                style='margin-top:18.0pt;margin-right:0cm;margin-bottom:8.0pt;margin-left:21.55pt;text-align:justify;text-indent:-21.55pt;border:none;padding:0cm;font-size:24px;font-family:"Arial Nova Light",sans-serif;color:#C00000;'>
                                <span style='font-family:"Arial Nova Light";'>7&nbsp; &nbsp; &nbsp;</span>Gesti&oacute;n
                                de Riesgos</h1>
                        </div>
                        <p
                            style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;line-height:110%;font-size:15px;font-family:"Arial Nova Light",sans-serif;'>
                            Todos los sistemas sujetos a esta Pol&iacute;tica deber&aacute;n realizar un an&aacute;lisis
                            de riesgos, evaluando las amenazas y los riesgos a los que est&aacute;n expuestos. Este
                            an&aacute;lisis se repetir&aacute;:</p>
                        <ul style="list-style-type: disc;">
                            <li>Regularmente, al menos una vez al a&ntilde;o cuando cambie la informaci&oacute;n
                                manejada.</li>
                            <li>Cuando cambien los servicios prestados.</li>
                            <li>Cuando ocurra un incidente grave de seguridad.</li>
                            <li>Cuando se reporten vulnerabilidades graves.</li>
                        </ul>
                        <p
                            style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;line-height:110%;font-size:15px;font-family:"Arial Nova Light",sans-serif;'>
                            Para la armonizaci&oacute;n de los an&aacute;lisis de riesgos, el Comit&eacute; de Seguridad
                            TIC establecer&aacute; una valoraci&oacute;n de referencia para los diferentes tipos de
                            informaci&oacute;n manejados y los diferentes servicios prestados. El Comit&eacute; de
                            Seguridad TIC dinamizar&aacute; la disponibilidad de recursos para atender a las necesidades
                            de seguridad de los diferentes sistemas, promoviendo inversiones de car&aacute;cter
                            horizontal.</p>
                        <div
                            style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;line-height:110%;font-size:15px;font-family:"Arial Nova Light",sans-serif;border:none;border-bottom:solid #C00000 1.0pt;padding:0cm 0cm 1.0pt 0cm;'>
                            <h1
                                style='margin-top:18.0pt;margin-right:0cm;margin-bottom:8.0pt;margin-left:21.55pt;text-align:justify;text-indent:-21.55pt;border:none;padding:0cm;font-size:24px;font-family:"Arial Nova Light",sans-serif;color:#C00000;'>
                                <span style='font-family:"Arial Nova Light";'>8&nbsp; &nbsp; &nbsp;</span>Obligaciones
                                del Personal</h1>
                        </div>
                        <p
                            style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;line-height:110%;font-size:15px;font-family:"Arial Nova Light",sans-serif;'>
                            Todos los miembros de la entidad tienen la obligaci&oacute;n de conocer y cumplir esta
                            Pol&iacute;tica de Seguridad de la Informaci&oacute;n y la Normativa de Seguridad, siendo
                            responsabilidad del Comit&eacute; de Seguridad TIC disponer los medios necesarios para que
                            la informaci&oacute;n llegue a los afectados.</p>
                        <p
                            style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;line-height:110%;font-size:15px;font-family:"Arial Nova Light",sans-serif;'>
                            Todos los miembros de la entidad atender&aacute;n a una sesi&oacute;n de
                            concienciaci&oacute;n en materia de seguridad TIC al menos una vez al a&ntilde;o. Se
                            establecer&aacute; un programa de concienciaci&oacute;n continua para atender a todos los
                            miembros de la entidad, en particular a los de nueva incorporaci&oacute;n.</p>
                        <p
                            style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;line-height:110%;font-size:15px;font-family:"Arial Nova Light",sans-serif;'>
                            Las personas con responsabilidad en el uso, operaci&oacute;n o administraci&oacute;n de
                            sistemas TIC recibir&aacute;n formaci&oacute;n para el manejo seguro de los sistemas en la
                            medida en que la necesiten para realizar su trabajo. La formaci&oacute;n ser&aacute;
                            obligatoria antes de asumir una responsabilidad, tanto si es su primera asignaci&oacute;n o
                            si se trata de un cambio de puesto de trabajo o de responsabilidades en el mismo.</p>
                        <div
                            style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;line-height:110%;font-size:15px;font-family:"Arial Nova Light",sans-serif;border:none;border-bottom:solid #C00000 1.0pt;padding:0cm 0cm 1.0pt 0cm;'>
                            <h1
                                style='margin-top:18.0pt;margin-right:0cm;margin-bottom:8.0pt;margin-left:21.55pt;text-align:justify;text-indent:-21.55pt;border:none;padding:0cm;font-size:24px;font-family:"Arial Nova Light",sans-serif;color:#C00000;'>
                                <span style='font-family:"Arial Nova Light";'>9&nbsp; &nbsp; &nbsp;</span>Terceras
                                Partes</h1>
                        </div>
                        <p
                            style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;line-height:110%;font-size:15px;font-family:"Arial Nova Light",sans-serif;'>
                            Cuando la Entidad preste servicios a otros organismos o maneje informaci&oacute;n de otros
                            organismos, se les har&aacute; part&iacute;cipes de esta Pol&iacute;tica de Seguridad de la
                            Informaci&oacute;n, se establecer&aacute;n canales para reporte y coordinaci&oacute;n de los
                            respectivos Comit&eacute;s de Seguridad TIC y se establecer&aacute;n procedimientos de
                            actuaci&oacute;n para la reacci&oacute;n ante incidentes de seguridad.</p>
                        <p
                            style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;line-height:110%;font-size:15px;font-family:"Arial Nova Light",sans-serif;'>
                            Cuando la Entidad utilice servicios de terceros o ceda informaci&oacute;n a terceros, se les
                            har&aacute; part&iacute;cipes de esta Pol&iacute;tica de Seguridad y de la Normativa de
                            Seguridad que ata&ntilde;a a dichos servicios o informaci&oacute;n. Dicha tercera parte
                            quedar&aacute; sujeta a las obligaciones establecidas en dicha normativa, pudiendo
                            desarrollar sus propios procedimientos operativos para satisfacerla. Se establecer&aacute;n
                            procedimientos espec&iacute;ficos de reporte y resoluci&oacute;n de incidencias. Se
                            garantizar&aacute; que el personal de terceros est&aacute; adecuadamente concienciado en
                            materia de seguridad, al menos al mismo nivel que el establecido en esta Pol&iacute;tica.
                        </p>
                        <p
                            style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;line-height:110%;font-size:15px;font-family:"Arial Nova Light",sans-serif;'>
                            Cuando alg&uacute;n aspecto de la Pol&iacute;tica no pueda ser satisfecho por una tercera
                            parte seg&uacute;n se requiere en los p&aacute;rrafos anteriores, se requerir&aacute; un
                            informe del Responsable de Seguridad que precise los riesgos en que se incurre y la forma de
                            tratarlos. Se requerir&aacute; la aprobaci&oacute;n de este informe por los responsables de
                            la informaci&oacute;n y los servicios afectados antes de seguir adelante.</p>
                    </div>
                </div>
            </div>
        </div>
    </default-layout>
</template>

<script>
import DefaultLayout from '../layouts/DefaultLayout.vue'
export default {
    component: {
        DefaultLayout
    },
    data() {
        return {

        }
    },
    methods: {
        goToCastillosYPalacios() {
            let $vm = this;
            $vm.$router.push('/castillos')
        }
    },

}
</script>

<style scoped>
.text-columns {
    column-count: 3;
    /* height: 50vh; */
}

.disable-selector {
    color: rgba(225, 225, 225);
}

.slide-show-container {
    position: relative;
    width: 100%;
    margin: auto;
}

.spainhn-slides {
    display: none;
}

/* Next & previous buttons */
.prev,
.next {
    cursor: pointer;
    position: absolute;
    top: 50%;
    width: auto;
    margin-top: -22px;
    padding: 16px;
    color: white;
    font-weight: bold;
    font-size: 18px;
    transition: 0.6s ease;
    border-radius: 0 3px 3px 0;
    user-select: none;
}

/* Position the "next button" to the right */
.next {
    right: 0;
    border-radius: 3px 0 0 3px;
}

.legal-main li {
    width: 100%;
    overflow-wrap: break-word;
}

/* Fading animation */
.fade {
    animation-name: fade;
    animation-duration: 10s;
}

@keyframes fade {
    from {
        opacity: .4
    }

    to {
        opacity: 1
    }
}

@media (min-width: 768px) {
    .text-columns {
        column-count: 3;
        /* height: 50vh; */
    }
}

@media (max-width: 768px) {
    .text-columns {
        column-count: 2;
        /* height: 50vh; */
    }
}

@media (max-width: 424px) {
    .text-columns {
        column-count: 1;
        /* height: 50vh; */
    }
}
</style>
